@font-face {
    font-family: 'togedapp';
    src: url(./assets/fonts/togedapp.ttf);
}
html {
    height: 100%;
    width: 100%;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
}
body {
    color: #2d353c;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    background-color: #eaeff2;
    background-image: url("./assets/images/background-day.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    transition: background-image ease 1s;

    &.day {
        background-image: url("./assets/images/background-day.jpg");
    }

    &.night {
        background-image: url("./assets/images/background-night.jpg");
    }

    &.lesmills-background {
        background-image: url("./assets/images/background-lesmills2.jpg");
    }

    .ui-toolbar {
        border: unset;
        border-bottom: 1px solid #dbdbdb;
        border-radius: 10px 10px 0px 0px;
    }

    p-progressspinner {
        position: absolute;
        top: calc(50% - 25px);
        left: calc(50% - 25px);
    }
    
    @keyframes ui-progress-spinner-color {
        100%,
        0% {
            stroke: white;
        }
        40% {
            stroke: white;
        }
        66% {
            stroke: white;
        }
        80%,
        90% {
            stroke: white;
        }
    }

    .p-toast {
        .p-toast-message.p-toast-message-success,
        .p-toast-message.p-toast-message-error,
        .p-toast-message.p-toast-message-warn,
        .p-toast-message.p-toast-message-info {
            background-color: #ffffff80;
            border: 0 none;
            border-radius: 5px;
            text-shadow: 0.5px 0.5px 3px rgba(0, 0, 0, 0.4);
            backdrop-filter: blur(10px) saturate(80%);
            color: unset;
            .p-toast-icon-close {
                color: unset;
            }
            .p-toast-message-icon {
                color: unset;
            }
        }
    }

    .ui-inputtext.ng-dirty.ng-invalid {
        box-shadow: 0px 0px 5px #a80000;
    }

    .input-error {
        background-color: #a8000033;
        border-radius: 0px 0px 3px 3px;
        font-size: 0.8em;
        padding: 5px;
        margin-top: -1px;
        color: #a80000;
    }

    .p-dialog {
        background-color: #ffffffcc;
        backdrop-filter: blur(10px) brightness(200%);
        border-radius: 10px;
        .p-dialog-header {
            border: unset;
            background-color: unset;
        }
        .p-dialog-content {
            background-color: unset;
            border: unset;
        }
        .p-dialog-footer {
            border: unset;
            background-color: unset;
        }
    }

    .p-progressbar {
        &.p-progressbar-determinate {
            border-radius: unset;
            .p-progressbar-value-animate {
                border-radius: unset;
                transition: width 250ms ease-in-out;
            }
        }
    }
}
:focus, button:focus{
    outline: none;
}

.mi {
    font-family: 'Material Icons Two Tone';
    font-weight: normal;
    font-style: normal;
    font-size: 2em;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    user-select: none;

    &.filled {
        font-family: 'Material Icons';
    }
}

.simplebar-maxheight {
    height: calc(100% - 50px);
}

.simplebar-content-padding {
    padding-bottom: 49px !important;
}